$('html').removeClass('no_js');
$('html').addClass('js');
//Hee
var AppSettings = {
    DEBUGMODE: true //change to turn on/off console.log statements
};

/**
 * Simple debug setup for logging to the console
 * @type {Object}
 */
var Debug = {
    log: function(string, variable) {
        if (AppSettings.DEBUGMODE) {
            try {
                console.log(string, variable);
            } catch (e) {}
        }
    },
    warn: function(string, variable) {
        if (AppSettings.DEBUGMODE) {
            try {
                console.warn(string, variable);
            } catch (e) {}
        }
    }
};

var projLink = {
    init: function(){
        $(document).on("click", 'a.plus-link', function(e) {

            e.preventDefault();
            var content = $('#main2').smoothState({
                // prefetch: true,
                // pageCacheSize: 6,
                anchors: 'a.ziplink',
                onStart: {
                    // How long this animation takes
                    duration: 0,
                    // A function that dictates the animations that take place
                    render: function($container) {}
                },
                onProgress: {
                    // How long this animation takes
                    duration: 0,
                    // A function that dictates the animations that take place
                    render: function($container) {}
                },
                onReady: {
                    duration: 0,
                    // `$container` is a `jQuery Object` of the the current smoothState container
                    // `$newContent` is a `jQuery Object` of the HTML that should replace the existing container's HTML.
                    render: function($container, $newContent) {
                        // Update the HTML on the page
                        $container.html($newContent);
                    }
                },
                onAfter: function($container, $newContent) {

                    $('body').addClass('content-is-open');
                    // $('#main2').stellar('refresh');
                    // buttonColor();

                }
            }).data('smoothState');

            var href = $(this).attr('href');
            content.load(href)

        }); 
    }
}


var smoothStateStart = {
    init: function(){
        $(document).on("click", 'a.smooth-link', function(e) {
            e.preventDefault();
            var href = $(this).attr('href');

            var content = $('#main').smoothState({
                // prefetch: true,
                // pageCacheSize: 6,
                anchors: 'a.smooth-link',
                onStart: {
                    // How long this animation takes
                    duration: 0,
                    // A function that dictates the animations that take place
                    render: function($container) {}
                },
                onProgress: {
                    // How long this animation takes
                    duration: 0,
                    // A function that dictates the animations that take place
                    render: function($container) {}
                },
                onReady: {
                    duration: 0,
                    // `$container` is a `jQuery Object` of the the current smoothState container
                    // `$newContent` is a `jQuery Object` of the HTML that should replace the existing container's HTML.
                    render: function($container, $newContent) {
                        // Update the HTML on the page
                        $container.html($newContent);
                    }
                },
                onAfter: function($container, $newContent) {

                    $('body').addClass('content-is-open');
                    $('body').removeClass('menu-open');
                    smoothStateStart.reset();

                }
            }).data('smoothState');

            content.load(href)
        }); 
    },
    reset: function() {
        var first = $(location).attr('pathname');
        first.indexOf(1);
        first.toLowerCase();
        first = first.split("/")[1];
        console.log(first)

        if (first == '') {
            $('#pp-nav').remove();
            pagePiling.init()
            $('body').addClass('homepage');
            $('body').removeClass('page');
        }

        if (first !== '') {
            $('#pp-nav').remove();
            $('body').removeClass('homepage');
            $('body').addClass('page');
        }

        if (first == 'projects') {
            filtering.init()
        }

        if(first == 'contact') {
            $('body').addClass('contact-page');
        }

        if(first !== 'contact') {
            $('body').removeClass('contact-page');
        }

    }
}


var headRoom = {
    init: function(){
        $("body").headroom();
        $(".hamburger-box").click(function(e) {
            $('body').toggleClass('menu-open');
        })
    }
}


var filtering = {
    init: function(){

        // init Isotope
        var $grid = $('.grid').isotope({
            itemSelector: '.grid-item',
            percentPosition: true,
            masonry: {
              // use outer width of grid-sizer for columnWidth
              columnWidth: '.grid-sizer',
              gutter: '.gutter-sizer'
            }
        });
        // filter items on button click
        $('.filter-button-group').on( 'click', 'button', function() {
            var filterValue = $(this).attr('data-filter');
            $grid.isotope({ filter: filterValue });

            window.location.hash = filterValue.substr(1);
        });

        // project/Theme sort
        $('ul.filter-toggle').on( 'click', 'li', function() {
            var filterValue = $(this).attr('data-filter');
            $grid.isotope({ filter: filterValue });
        });

        $(document).on("click", '.filter-button', function(e) {
            $('.filter-button').removeClass('active');
            $(this).addClass('active');
        });

        $(document).on("click", '.filter-toggle li', function(e) {
            $('.filter-button').removeClass('active');
            $('.filter-toggle li').removeClass('active');
            $(this).addClass('active');

            if($(this).data('filter') == '.all-themes') {
                $('#product-filters').hide();
                $('#theme-filters').show();
            } else {
                $('#product-filters').show();
                $('#theme-filters').hide();
            }
        })

        var filterItem = '.' + location.hash.substr(1)

        if (themeFilters.includes(filterItem) || productFilters.includes(filterItem)) {

            if(productFilters.includes(filterItem)) {
                $('#product-filters').show();
                $('#theme-filters').hide();
                $('.filter-toggle li').removeClass('active');
                $('.filter-toggle li:first-child').addClass('active');
            }

            if(themeFilters.includes(filterItem)) {
                $('#product-filters').hide();
                $('#theme-filters').show();
                $('.filter-toggle li').removeClass('active');
                $('.filter-toggle li:last-child').addClass('active');
            }

            $('button[data-filter^="'+filterItem+'"]').addClass('active');
            $grid.isotope({ filter: filterItem });

        } else {
            $('.filter-toggle li:first-child').trigger('click');
        }
        
    }
}

var pagePiling = {
    init: function(){
        if(typeof slidedata !== 'undefined') {
            var refreshPiling = setInterval(pagePiling.autopileFunction, 8500);

            $('body').bind('touchstart mousedown', function(e) {
                autopile = false;
                clearInterval(refreshPiling);
            });

            $('#floating-holder').css("background-color", slidedata[0]['block-bgcolor']);
            $('#homepage-slides').pagepiling({
                onLeave: function(index, nextIndex, direction) {
                    if (direction == 'down') {
                        pdirection = 'down';
                        $('#static_info').addClass('is-exiting-up');
                    } else {
                        pdirection = 'up';
                        $('#static_info').addClass('is-exiting-down');
                    }

                    $('#floating-holder').css("background-color", slidedata[nextIndex - 1]['block-bgcolor']);

                },
                afterLoad: function(anchorLink, index) {
                    $('#static_info').removeClass('is-exiting-up is-exiting-down');
                    pagePiling.fillPiling(index, 'Up');
                },
                afterRender: function() {
                    pagePiling.fillPiling(1);
                },
                sectionsColor: scolors,
                loopTop: true,
                loopBottom: true,
            });
        }
    },
    autopileFunction: function() {
        if (autopile) {
            $("#ppNext").trigger("click");
        }
    },
    fillPiling: function(index) {
        var fillsBlock = '<div class="colder"><span class="client belement--fadeIn' + pdirection + ' belement">' + slidedata[index - 1]['client'] + '</span></div>';
        fillsBlock += '<div class="clear clearfix"></div><div class="hholder belement--fadeIn' + pdirection + ' belement">';
        fillsBlock += '<h2>' + slidedata[index - 1]['heading'] + '</h2><p>' + slidedata[index - 1]['description'] + '</p></div>';
        if (slidedata[index - 1]['entry_id'] == 21) {
            $('#floating-holder').addClass('dark')
            fillsBlock += '<div class="plus-content belement--fadeIn' + pdirection + '  belement"><a href="/about" class="cd-nav-plus circ-button circ-small"><img src="http://lushomo.net/lib/img/black-line.png" class="cd-nav-icon1" /><img src="http://lushomo.net/lib/img/black-line.png" class="cd-nav-icon2" /></a></div>';
        } else {
            $('#floating-holder').removeClass('dark')
            fillsBlock += '<div class="plus-content belement--fadeIn' + pdirection + '  belement"><span class="minfo noanimation minfo--top">View More</span><a href="/projects/' + slidedata[index - 1]['link'] + '" class="cd-nav-plus plus-link circ-button circ-small"><img src="http://lushomo.net/lib/img/black-line.png" class="cd-nav-icon1" /><img src="http://lushomo.net/lib/img/black-line.png" class="cd-nav-icon2" /></a></div>';
        }
        $('#static_info').html(fillsBlock);
    }
};



var aboutFeatures = {
    init: function(){
        $(document).on("click", 'ul.about-feature-titles li', function(e) {
            $('ul.about-feature-titles>li, ul.about-feature-text>li').removeClass('active');
            $(this).addClass('active');
            $('ul.about-feature-text>li.' + $(this).data('target')).addClass('active')
        })
    }
}

var carousels = {
    init: function(){
        $(".project_carousel").owlCarousel({
            items:1,
            dots:true,
            loop: true,
            autoplay:true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true
        });
    }
}

var videoModals = {
    init: function(){

        var $videoSrc;  

        $('.video-btn').click(function() {
            $videoSrc = $(this).data( "src" );
        });

        $('#videoModal').on('shown.bs.modal', function (e) {
            $("#video").attr('src',$videoSrc + "?mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent" ); 
        })

        $('#videoModal').on('hide.bs.modal', function (e) {
            $("#video").attr('src',$videoSrc); 
        }) 
    }
}

var expandingImage = {
    init: function(){
        let originalHeight = 0
        jQuery('.expandImageButton').click(function ( event ) {
            event.preventDefault()

            jQuery(this).prev('.imageContainer').stop( true )
            let value = jQuery('.expandingDisplayImage').outerHeight() + 'px'

            jQuery('.expandImageButton').toggleClass('imageIsCurrentlyExpanded')

            if (jQuery('.expandImageButton').hasClass('imageIsCurrentlyExpanded')) {
                originalHeight = jQuery('.imageContainer').outerHeight() + 'px'
                jQuery(this).prev('.imageContainer').animate({'max-height': value}, 1000, 'swing')
            } else {
                jQuery(this).prev('.imageContainer').animate({'max-height': originalHeight}, 1000, 'swing')
            }
        })
    }
}

var Main = {
    run: function() {
        Debug.log('Custom Scripts Running');
        pagePiling.init();
        headRoom.init();
        // projLink.init();
        aboutFeatures.init();
        smoothStateStart.init();
        filtering.init();
        carousels.init();
        videoModals.init();
        expandingImage.init();
    }
};

$(document).ready(Main.run);